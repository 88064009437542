<template>
  <div>
    <p class="text-sm text-grey">
      A 2% interest accure daily when you miss a payment.
    </p>

    <div class="relative overflow-x-auto shadow-md sm:rounded-lg pt-4">
      <table class="w-full text-sm">
        <thead class="text-grey">
          <tr>
            <th scope="col" class="px-6 py-3 text-center">Date</th>
            <!-- <th scope="col" class="px-6 py-3">Amount</th> -->
            <th scope="col" class="px-6 py-3 text-center">Interest</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in paymentBreakdown"
            :key="item.id"
            class="text-center"
          >
            <td scope="row" class="px-6 py-4">
              {{ dayjs(item.createdAt).format("MMMM DD, YYYY") }}
            </td>
            <td class="px-6 py-4">{{ item.amount | formatMoney }}</td>
            <!-- <td class="px-6 py-4">Laptop</td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  props: {
    paymentBreakdown: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  computed: {
    dayjs() {
      return dayjs;
    },
  },
};
</script>
<style scoped>
/* .section {
  margin-top: 1rem;
} */
tr:nth-child(even) {
  background: #f3f3f6;
}
</style>