var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", { staticClass: "text-sm text-grey" }, [
      _vm._v(" A 2% interest accure daily when you miss a payment. "),
    ]),
    _c(
      "div",
      { staticClass: "relative overflow-x-auto shadow-md sm:rounded-lg pt-4" },
      [
        _c("table", { staticClass: "w-full text-sm" }, [
          _vm._m(0),
          _c(
            "tbody",
            _vm._l(_vm.paymentBreakdown, function (item) {
              return _c("tr", { key: item.id, staticClass: "text-center" }, [
                _c(
                  "td",
                  { staticClass: "px-6 py-4", attrs: { scope: "row" } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dayjs(item.createdAt).format("MMMM DD, YYYY")
                        ) +
                        " "
                    ),
                  ]
                ),
                _c("td", { staticClass: "px-6 py-4" }, [
                  _vm._v(_vm._s(_vm._f("formatMoney")(item.amount))),
                ]),
              ])
            }),
            0
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "text-grey" }, [
      _c("tr", [
        _c(
          "th",
          { staticClass: "px-6 py-3 text-center", attrs: { scope: "col" } },
          [_vm._v("Date")]
        ),
        _c(
          "th",
          { staticClass: "px-6 py-3 text-center", attrs: { scope: "col" } },
          [_vm._v("Interest")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }